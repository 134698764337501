<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import ImageList from './components/image_list.vue';

formCreate.component('ImageList', ImageList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        // close: false,
      },
    };
  },
  async created() {
    const rule = await this.getFormRule('sfa_attendance_repair_clock_application_detail', 'CRM20210312000001402');
    rule.forEach((item) => {
      const v = item;
      v.props = {
        ...v.props,
        readonly: true,
      };
    });
    this.rule = rule;
    this.reload(this.rule);
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      // const requestUrl = '/sfa/sfaSignFormsController/exceptionReportDetail';
      const requestUrl = '/sfa/sfaSignFormsController/queryException'; // 之前的接口
      request.post(requestUrl, this.formConfig.id || this.id).then((res) => {
        if (res.success) {
          this.setValue(res.result);
        }
      });
    },
  },
};
</script>
