var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-container" },
    _vm._l(_vm.imageList, function (item, index) {
      return _c("el-image", {
        key: index,
        staticClass: "image",
        attrs: { "preview-src-list": _vm.imageList, src: item },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }