<template>
  <div class="image-container">
    <el-image class="image" v-for="(item,index) in imageList" :preview-src-list="imageList" :src="item" :key="index"></el-image>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          const list = JSON.parse(val);
          this.imageList = list.map((v) => v.picUrl + v.picPath);
        }
      },
    },
  },
  data() {
    return {
      imageList: [],
    };
  },
};
</script>
<style lang="less" scoped>
.image-container{
  display: flex;
  flex-wrap: wrap;
  .image{
    width: 80px;
    height: 80px;
    margin: 0 10px 10px 0;
  }
}
</style>
